import "bootstrap";
import "../styles/index.scss";
import "@fortawesome/fontawesome-free";
import "./slick";
import 'ekko-lightbox/dist/ekko-lightbox.js';

console.log("webpack starterkit");

// iPad and iPod detection
var isiPad = function () {
	return navigator.platform.indexOf("iPad") != -1;
};

var isiPhone = function () {
	return navigator.platform.indexOf("iPhone") != -1 || navigator.platform.indexOf("iPod") != -1;
};

// Parallax
var parallax = function () {
	$(window).stellar();
};

function getCopyYear() {
	var d = new Date; 
	document.getElementById("copyYear").innerHTML = d.getFullYear();
}

$(document).ready(function(){
  $('.customer-logos').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: 520,
      settings: {
        slidesToShow: 3
      }
    }]
  });
});



	// Reflect scrolling in navigation
	var navActive = function(section) {

		var $el = $('#navbar > ul');
		$el.find('li').removeClass('active');
		$el.each(function(){
			$(this).find('a[data-nav-section="'+section+'"]').closest('li').addClass('active');
		});

	};


// Document on load.
$(function () {
	getCopyYear();
});




$(document).on('click', '[data-toggle="lightbox"]', function(event) {
  event.preventDefault();
  $(this).ekkoLightbox({
    alwaysShowClose: false,
    showArrows: false
  });
});